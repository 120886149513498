import React from "react";
import {WarehouseBasePage} from "./Base";


export const WarehouseSalePage: React.FC = () => {
    const status = "Склад продаж"

    const preFilters = [
        {
            "id": "status",
            "value": [
                status
            ],
            "condition": "in_list",
            "kind": "select",
            "key": "filter-1720784755768",
            "nested_in": "attributes"
        }
    ]
    const columns = [
        "manufacturer_model",
        "manufacturer",
        "model",
        "hashrate",
        "id",
        "gtd",
        "customer",
        "date_receipt"
    ]
    const fields = [
        "status",
        "date_receipt",
        "customer",
        "manufacturer_model",
        "manufacturer",
        "model",
        "hashrate",
        "gtd",
        "sale_comment"
    ]
    return <WarehouseBasePage
        prefix={"sale"}
        columns={columns}
        preFilters={preFilters}
        title={"Склад продаж"}
        createStatus={status}
        fields={fields}
    />
}